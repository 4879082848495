

	.css-84cof2-MuiRating-root {
		display: -webkit-inline-box;
		display: -webkit-inline-flex;
		display: -ms-inline-flexbox;
		display: inline-flex;
		position: relative;
		font-size: 1.5rem;
		cursor: pointer;
		text-align: left;
		-webkit-tap-highlight-color: transparent;
		color: #000 !important;
	}

	.rating>span {
		margin: 0 4px;
		padding: 0 8px;
		border-radius: 4px;
		color: #000 !important;
	}

	.full {
		color: #000 !important;
		display: flex;
		justify-content: center;
		width: 100%;
		border-radius: 4px;
	}

	.activestar{
		background: #E8F7E0 !important;
	}

	.watermark{
		text-align: center;
		font-size: 150px;
		font-weight: bold;
		opacity: .2;
	}

	